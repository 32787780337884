@font-face {
  font-family: 'chocolate_covered_raindrops';
  src: url($assets-path + 'chocolate_covered_raindrops-webfont.woff2') format('woff2'),
       url($assets-path + 'chocolate_covered_raindrops-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.mainCallToAction {
  a.cta-button:not(.persist):not(.cta-donate){
    background: transparent;
    color: $brand-primary;
    border: 1px solid $brand-primary;
    &:hover {
      background-color: darken($brand-primary, 10%);
      color: white;
    }
  }
}

.menuMain {
  border-top: 1px solid $grey-dark;
  border-bottom: 1px solid $grey-dark;
  .cta-button {
    background: white!important;
    color:$brand-primary!important;
  }
  .header-search button {
    background: white;
    border-radius: 30px;
  }
}

@media (min-width: map-get($map: $breakpoints, $key: xl) + 1) {

  .pageHeader.sticky {
    .menuMain {
      display: none;
    }
  }

}

.carouselControlPrev:before{
  content: '\f137';
}
.carouselControlNext:before {
  content: '\f138';
}

.homeIntro {
  &:before {
    content: '';
    display: block;
    position: relative;
    margin: 0 auto 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    width: 75px;
    height: 69px;
    background-image: url($assets-path + 'logo-icon.png');
  }
  h2 {
    font-family: 'chocolate_covered_raindrops';
    font-size: $font-size-h1;
    font-weight: normal;
    color: $brand-primary;
  }
  .embed-container {
    max-width: 1000px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.homefeaturecategory-homeboximpactstats:before {
  background-position: center left 10px;
}

.footerBox[class*="ewsletter"] {
  button {
    border: 1px solid white!important;
  }
  a:not(.button) {
    color: white;
  }
}

.Footercredits ul {
  li{
  display: inline-block;
  padding: 10px;
  }
  &.footerLinks {
    margin: 20px 0 30px;
    li a {
      background-color: transparent;
      color: $brand-primary;
      text-decoration: none!important;
      padding: 0.75em 1.5em;
      border-radius: 30px;
      border: 1px solid $brand-primary;
      &:hover {
        background-color: $brand-primary;
        color: white;
      }
    }
  }
}

// Posts

.cta-button.yellow {
  border-color: $yellow;
  &:hover {
    background-color: darken($yellow, 10%);
    border-color: darken($yellow, 10%);
  }
}

.associated.associatedRelatedPosts,
.associated.associatedMostRead {
  background-color: $grey-lighter;
  padding: $spacer * 2;
}

.eventsPostBody {
  .associated {
    display: none;
  }
}

// Donation form thank you

.donationThankYouWrapper {
  padding-top: $spacer * 3;
}

// Shop


.productPostPage .post .postAside {
  display: block;
  width: 100%;
  .alsoBoughtProducts, .bestSellers {
    display: none;
  }
  ul.associatedListing li {
    //display: block;
  }
  ul.associatedListing {
    display: flex;
    flex-wrap: wrap;
    li {
      border-top: 0!important;
      margin: 0 20px 20px;
      padding-top: 0;
    }
  }
}



@include appeal-field("lualchristmas", "christmas");
@include appeal-field("lual22", "christmas");
@include appeal-field("PostCategory_light-up-a-life", "christmas");


body.appeal-lualchristmas,
body.appeal-lual22,
.PostCategory_light-up-a-life {
  .donateBtn.donate {
    background: $gold;
    border: none;
    &:hover {
      background: darken($gold, 5%);
    }
  }
  .appealPostPage {
    overflow: hidden;
  }
  @media (min-width: map-get($map: $breakpoints, $key: lg)) {

    .headerText{
      position: relative;
      &:after {
        content: '';
        width: 614px;
        height: 592px;
        position: absolute;
        top: -150px;
        right: 0;
        background-image: url($assets-path + 'logo-burst.png');
        background-position: cover;
      }
    }

  }
  // totaliser
  .appealTotaliser {
    border: none;
    padding: 0;
  }
  span.totaliserBG {
    @media (min-width: map-get($map: $breakpoints, $key: xl) + 1) {
      height: 1140px;
    }
    @media (max-width: map-get($map: $breakpoints, $key: xl) + 1) {
      height: calc(100vw - 40px);
    }
    border-radius: 30px;
  }
  .targetWrapper,
  .raisedWrapper {
    color: $brand-primary;
  }
  .post .contentBlock .postContent {
    grid-column: 1 / 3;
  }
  .postAside {
    display: block;
    width: 100%;
  }
  .appealTotaliser.totaliserPanel {
    display: block!important;
  }
  // temporarily hack totaliser
  // span.totaliserDone {
  //   height: 1%!important;
  // }
}

.PostCategory_light-up-a-life {
  @media (min-width: map-get($map: $breakpoints, $key: lg)) {

    .headerText{
      position: relative;
      &:after {
        background-image: url($assets-path + 'logo-burst-2023.png');
      }
    }
  }
}


// Flowers IM-1262 15.4.23

@include appeal-field("lavenderappeal23", "flowers");

body.appeal-lavenderappeal23 {
  .donateBtn.donate {
    background: $brand-primary;
    border: none;
    &:hover {
      background: darken($brand-primary, 5%);
    }
  }
  .appealPostPage {
    overflow: hidden;
  }
  
  // totaliser
  .appealTotaliser {
    border: none;
    padding: 50px 0 0;
  }
  span.totaliserBG {
    @media (min-width: map-get($map: $breakpoints, $key: xl) + 1) {
      height: 1140px;
    }
    @media (max-width: map-get($map: $breakpoints, $key: xl) + 1) {
      height: calc(100vw - 40px);
    }
    border-radius: 30px;
  }
  .targetWrapper,
  .raisedWrapper {
    color: $brand-primary;
  }
  .post .contentBlock .postContent {
    grid-column: 1 / 3;
  }
  .postAside {
    display: block;
    width: 100%;
  }
  .appealTotaliser.totaliserPanel {
    display: block;
  }
}


// Hide donate/fundraise buttons and totaliser on dummy appeal which is now just being used as a landing page

body.appeal-lual21 .appealActionsWrapper {
  display: none;
}

body.appeal-lual21 .appealWidgetsWrapper {
  display: none;
}

// CR-262

span#countdownText0 {
  color: $brand-primary;
  font-size: 2.5em;
  font-weight: bold;
  font-family: 'chocolate_covered_raindrops';
  span.countdownDigits {
    font-size: 2.5em;
    margin-right: 10px;
  }
}

// https://raisingit.atlassian.net/browse/DES-5886
.homeFeed:not(:first-of-type) {
  padding-top: 0;
}

.appealActions .cta-button {
    border: 0px;
}

// IM 1262 15/04/23 CHANGE SIZE OF FLOWER //
body.appeal-lavenderappeal23 .commentsList .commentDonation:before {
  width: 80px;
  height: 100px;
}

// 666228 Make appeals page full-width
.postAside.appealWidgetsWrapper {
  width: 100% !important;
}
